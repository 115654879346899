import React from 'react'

const SpinnerSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <title>Loading spinner</title>
      <path
        opacity="0.24"
        d="M29.3346 16.0003C29.3346 23.3641 23.365 29.3337 16.0013 29.3337C8.6375 29.3337 2.66797 23.3641 2.66797 16.0003C2.66797 8.63653 8.6375 2.66699 16.0013 2.66699C19.9836 2.66699 23.5581 4.41285 26.0013 7.18091C28.0758 9.53135 29.3346 12.6188 29.3346 16.0003Z"
        stroke="#143C6E"
        strokeWidth="2"
      />
      <path
        d="M16 2.66699C19.9823 2.66699 23.5568 4.41285 26 7.18091"
        stroke="#143C6E"
        strokeWidth="2"
      />
    </svg>
  )
}

export default SpinnerSvg
