import type { IFooterLinksContent } from '../shared/interface/footer.interface'
export const footerAdviceGuidanceLinks: IFooterLinksContent = {
  heading: 'Advice & Guidance',
  items: [
    {
      id: 1,
      title: 'Small Business Guide',
      url: 'https://www.ncsc.gov.uk/collection/small-business-guide',
      testid: 'small-business-guide',
    },
    {
      id: 2,
      title: 'Newsletter',
      url: 'https://www.ncsc.gov.uk/register',
      testid: 'newsletter',
    },
    {
      id: 3,
      title: 'Cyber Aware',
      url: 'https://www.ncsc.gov.uk/cyberaware/home',
      testid: 'cyber-aware',
    },
    {
      id: 4,
      title: 'Cyber Action Plan',
      url: 'https://www.ncsc.gov.uk/cyberaware/actionplan',
      testid: 'cyber-action-plan',
    },
  ],
}
