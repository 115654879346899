import React from 'react'

import SpinnerSvg from './SpinnerSvg'
import styles from './spinner.module.scss'

/**
 * A configurable spinner. Change variant to 'legacy' for classic
 * loading spinner, included in case of design rollback, utilises
 * the size prop. See component story for theming.
 * *NOTE: Default Spinner may not be visible in docs view - switch to canvas*
 */

export const Spinner = () => {
  return (
    <div aria-label="loading" className={`${styles.spinner} spinner-style`}>
      <SpinnerSvg />
    </div>
  )
}

export default Spinner
