import type { IFooterLinksContent } from '../shared/interface/footer.interface'

export const footerContactLinks: IFooterLinksContent = {
  heading: 'Contact the NCSC',
  items: [
    {
      id: 1,
      title: 'General enquiries',
      url: 'https://www.ncsc.gov.uk/section/about-this-website/general-enquiries',
      testid: 'general-enquiries',
    },
    {
      id: 2,
      title: 'Media Centre',
      url: 'https://www.ncsc.gov.uk/section/about-ncsc/media-centre',
      testid: 'media-centre',
    },
    {
      id: 3,
      title: 'Report an incident',
      url: 'https://report.ncsc.gov.uk/',
      testid: 'report-incident',
    },
    {
      id: 4,
      title: 'Report a vulnerability',
      url: 'https://www.ncsc.gov.uk/information/vulnerability-reporting',
      testid: 'report-vulnerability',
    },
    {
      id: 4,
      title: 'Report a phishing scam',
      url: 'https://www.ncsc.gov.uk/collection/phishing-scams',
      testid: 'phishing-scams',
    },
  ],
}
